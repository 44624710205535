body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: gainsboro;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.selector-container {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 50px;

  .check {
    width: 50px;
    height: 50px;
    margin-left: -23%;
    position: absolute;
    margin-top: -2%;
  }

  .selector-box {
    padding: 20px;
    box-shadow: 6.7px 6.7px 5.3px rgba(0, 0, 0, 0.024),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.036);
    border-radius: 10px;
    width: 45%;
    align-items: center;

    :hover {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 600px) {
  .selector-container {
    display: flex;
    flex-direction: column !important;

    .selector-box {
      width: 100%;
    }

    .check {
      width: 50px;
      height: 50px;
      margin-left: -45%;
      margin-top: -5%;
      position: absolute;
    }
  }
}

div.outOfStock {
  border: 1px solid gray;
  border-radius: 8px;
  width: 65%;
  align-self: center;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
  p {
    color: gray;
    align-self: center;
    padding: 0;
    margin: 0;
  }
}

.product__item__text {
  display: flex;
  flex-direction: column;
}
