/*---------------------
  Header
-----------------------*/
@import "variable";
.header {

}

.header-logo {
    max-height: 105px;
    margin-top: -15px;
    //width: 150px;
}

.header__logo {
    //padding: 26px 0 30px;

    a {
        display: inline-block;
    }
}

.header__options {
    padding: 32px 0;
    text-align: right;
}

.header__menu {
    display: inline-block;
    margin-right: 65px;

    ul {

        li {
            list-style: none;
            display: inline-block;
            margin-right: 40px;
            position: relative;

            &.active {

                a {
                    color: $primary-color;
                }
            }

            &:hover {

                a {
                    color: $primary-color;
                }

                .dropdown {
                    top: 30px;
                    opacity: 1;
                    visibility: visible;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            .dropdown {
                position: absolute;
                left: 0;
                top: 56px;
                width: 150px;
                background: $heading-color;
                text-align: left;
                padding: 5px 0;
                z-index: 9;
                opacity: 0;
                visibility: hidden;
                //@include transition(all, .3s); TODO

                li {
                    display: block;
                    margin-right: 0;

                    a {
                        font-size: 14px;
                        color: $white-color;
                        font-weight: 400;
                        padding: 5px 20px;
                        text-transform: capitalize;

                        &:after {
                            display: none;
                        }
                    }
                }
            }

            a {
                font-size: 16px;
                font-family: 'Oswald', sans-serif;
                color: $heading-color;
                display: block;
                font-weight: 500;
                padding: 3px 0;
                text-transform: uppercase;
                //@include transition(all, .3s); TODO
            }
        }
    }
}

.header__option__right {
    display: inline-block;
}

.header__option__right__search {
    display: inline-block;
    margin-right: 45px;

    img {
        cursor: pointer;
    }
}

.header__option__right__cart {
    display: inline-block;

    ul {
        display: inline-block;

        li {
            list-style: none;
            display: inline-block;
            margin-right: 25px;

            &:last-child {
                margin-right: 0;
            }

            img {
                display: inline-block;
                margin-right: 5px;
            }

            span {
                color: $heading-color;
                font-size: 14px;
                display: inline-block;
            }
        }
    }

    .price {
        display: inline-block;
        color: $primary-color;
        font-size: 14px;
        font-weight: 700;
    }
}

.offcanvas-menu-wrapper {
    display: none;
}

.canvas__open {
    display: none;
}
