/******************************************************************
  Theme Name: Florist
  Description: Florist - E-commerce Template
  Author: Colorib
  Author URI: https://www.colorib.com/
  Version: 1.0
  Created: Colorib
******************************************************************/

/*------------------------------------------------------------------
[Table of contents]

1.  Template default CSS
	1.1	Variables
	1.2	Mixins
	1.3	Flexbox
	1.4	Reset
2.  Helper Css
3.  Header Section
4.  Hero Section
5.  Banner Section
6.  Product Section
7.  Intagram Section
8.  Latest Section
9.  Contact
10.  Footer Style
-------------------------------------------------------------------*/

/*----------------------------------------*/

/* Template default CSS
/*----------------------------------------*/

/* --- Google Font --- */

@import 'bootstrap.min.css';
@import 'elegant-icons.css';
@import 'font-awesome.min.css';
@import 'magnific-popup.css';
@import 'nice-select.css';
//@import 'owl.carousel.min.css';
@import 'slicknav.min.css';

@import 'variable';

@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Quicksand:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Hello Santtiny";
  src: url("../fonts/HelloSanttinyRegular.woff2") format("woff2"), url("../fonts/HelloSanttinyRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Better Together";
  src: local('BetterTogether'), url("../fonts/BetterTogether.ttf") format('truetype');
}

@font-face {
  font-family: "Kitchen Home";
  src: local('KitchenHome'), url("../fonts/KitchenHome.ttf") format('truetype');
}

@font-face {
  font-family: "Always Forever";
  src: local('AlwaysForever'), url("../fonts/AlwaysForever.ttf") format('truetype'), url("../fonts/AlwaysForeverBold.ttf") format('truetype');
}

@font-face {
  font-family: "Baby Names";
  src: local('BabyNames'), url("../fonts/BabyNames.otf") format('opentype');
}

@font-face {
  font-family: "Stunders";
  src: local('Stunders'), url("../fonts/Stunders-PersonalUse.ttf") format('opentype');
}

.product__item__text > a {
  h5 {
    font-family: 'Always Forever', sans-serif;
    //font-weight: bold;
    font-size: 52px;
  }
}

nav.header__menu > ul > li > a {
  font-family: "Kitchen Home", sans-serif;
  font-weight: bold;
  font-size: 28px;
}

.breadcrumb__text > h2 {
  font-family: 'Better Together', sans-serif;
  font-weight: bold;
}

html, body {
  height: 100%;
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: #111111;
  font-weight: 400;
  font-family: "QuickSand", sans-serif;
  //font-family: "Libre Baskerville", serif;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
  color: #444444;
  font-weight: 400;
  line-height: 27px;
  margin: 0 0 15px 0;
}

img {
  max-width: 100%;
}

input:focus, select:focus, button:focus, textarea:focus {
  outline: none;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
  color: #ffffff;
}

ul, ol {
  padding: 0;
  margin: 0;
}

/*---------------------
  Helper CSS
-----------------------*/

.section-title {
  margin-bottom: 45px;
}

.section-title span {
  color: $primary-color;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: block;
  margin-bottom: 8px;
}

.section-title h2 {
  font-size: 42px;
  color: #111111;
  line-height: 1.4;
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.spad {
  padding-top: 100px;
  padding-bottom: 100px;
}

.text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6, .text-white p, .text-white span, .text-white li, .text-white a {
  color: #fff;
}

/* buttons */

.primary-btn {
  display: inline-block;
  font-size: 15px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: #f45d96;
  padding: 14px 30px 12px;
  border-radius: 50px;
}

.primary-btn.white-btn {
  color: #f45d96;
  background: #ffffff;
}

.primary-btn.border-btn {
  background: transparent;
  color: #111111;
  border: 2px solid #f45d96;
}

.site-btn {
  border: none;
  display: inline-block;
  padding: 14px 30px;
  background: #111111;
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
}

/* Preloder */

#preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000;
}

.loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  border-radius: 60px;
  animation: loader 0.8s linear infinite;
  -webkit-animation: loader 0.8s linear infinite;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
  50% {
    -webkit-transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }
  100% {
    -webkit-transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

.spacial-controls {
  position: fixed;
  width: 111px;
  height: 91px;
  top: 0;
  right: 0;
  z-index: 999;
}

.spacial-controls .search-switch {
  display: block;
  height: 100%;
  padding-top: 30px;
  background: #323232;
  text-align: center;
  cursor: pointer;
}

.search-model {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  z-index: 99999;
}

.search-model-form {
  padding: 0 15px;
}

.search-model-form input {
  width: 500px;
  font-size: 40px;
  border: none;
  border-bottom: 2px solid #333;
  background: 0 0;
  color: #999;
}

.search-close-switch {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #333;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 28px;
  top: 30px;
  cursor: pointer;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  padding-top: 1px;
}

/*---------------------
  Header
-----------------------*/

.header__logo {
  padding: 26px 0 30px;
}

.header__logo a {
  display: inline-block;
}

.header__options {
  padding: 32px 0;
  text-align: right;
}

.header__menu {
  display: inline-block;
  margin-right: 65px;
}

.header__menu ul li {
  list-style: none;
  display: inline-block;
  margin-right: 40px;
  position: relative;
}

.header__menu ul li.active a {
  color: #f45d96;
}

.header__menu ul li:hover a {
  color: #f45d96;
}

.header__menu ul li:hover .dropdown {
  top: 30px;
  opacity: 1;
  visibility: visible;
}

.header__menu ul li:last-child {
  margin-right: 0;
}

.header__menu ul li .dropdown {
  position: absolute;
  left: 0;
  top: 56px;
  width: 150px;
  background: #111111;
  text-align: left;
  padding: 5px 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.header__menu ul li .dropdown li {
  display: block;
  margin-right: 0;
}

.header__menu ul li .dropdown li a {
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  padding: 5px 20px;
  text-transform: capitalize;
}

.header__menu ul li .dropdown li a:after {
  display: none;
}

.header__menu ul li a {
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  color: #111111;
  display: block;
  font-weight: 500;
  padding: 3px 0;
  text-transform: uppercase;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.header__option__right {
  display: inline-block;
}

.header__option__right__search {
  display: inline-block;
  margin-right: 45px;
}

.header__option__right__search img {
  cursor: pointer;
}

.header__option__right__cart {
  display: inline-block;
}

.header__option__right__cart ul {
  display: inline-block;
}

.header__option__right__cart ul li {
  list-style: none;
  display: inline-block;
  margin-right: 25px;
}

.header__option__right__cart ul li:last-child {
  margin-right: 0;
}

.header__option__right__cart ul li img {
  display: inline-block;
  margin-right: 5px;
}

.header__option__right__cart ul li span {
  color: #111111;
  font-size: 14px;
  display: inline-block;
}

.header__option__right__cart .price {
  display: inline-block;
  color: #f45d96;
  font-size: 14px;
  font-weight: 700;
}

.offcanvas-menu-wrapper {
  display: none;
}

.canvas__open {
  display: none;
}

/*---------------------
  Hero
-----------------------*/

.hero__text span {
  font-size: 16px;
  color: #111111;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: block;
  margin-bottom: 12px;
  position: relative;
  top: 100px;
  -webkit-transition: all, 0.2s;
  -o-transition: all, 0.2s;
  transition: all, 0.2s;
  opacity: 0;
}

.hero__text h2 {
  color: #111111;
  font-size: 43px;
  line-height: 60px;
  margin-bottom: 28px;
  position: relative;
  top: 100px;
  -webkit-transition: all, 0.4s;
  -o-transition: all, 0.4s;
  transition: all, 0.4s;
  opacity: 0;
}

.hero__text .primary-btn {
  position: relative;
  top: 100px;
  -webkit-transition: all, 0.6s;
  -o-transition: all, 0.6s;
  transition: all, 0.6s;
  opacity: 0;
}

.hero__items {
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero__slider.owl-carousel .owl-item.active .hero__text span {
  top: 0;
  opacity: 1;
}

.hero__slider.owl-carousel .owl-item.active .hero__text h2 {
  top: 0;
  opacity: 1;
}

.hero__slider.owl-carousel .owl-item.active .hero__text .primary-btn {
  top: 0;
  opacity: 1;
}

.hero__slider.owl-carousel .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  width: 1380px;
  margin: 0 auto;
}

.hero__slider.owl-carousel .owl-dots button {
  height: 15px;
  width: 15px;
  background: #ffffff;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}

.hero__slider.owl-carousel .owl-dots button.active:before {
  opacity: 1;
}

.hero__slider.owl-carousel .owl-dots button.active:after {
  opacity: 1;
}

.hero__slider.owl-carousel .owl-dots button:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #f45d96;
  content: "";
  margin: 0 auto;
  border-radius: 50%;
  opacity: 0;
}

.hero__slider.owl-carousel .owl-dots button:after {
  position: absolute;
  left: 0;
  right: 0;
  top: 1px;
  height: 13px;
  width: 13px;
  border: 2px solid #eeeef0;
  content: "";
  margin: 0 auto;
  border-radius: 50%;
  opacity: 0;
}

.hero__slider.owl-carousel .owl-dots button:last-child {
  margin-right: 0;
}

/*---------------------
  Benefit
-----------------------*/

.benefits {
  padding-top: 60px;
  padding-bottom: 30px;
}

.benefits__item {
  margin-bottom: 30px;
  overflow: hidden;
}

.benefits__item__icon {
  float: left;
  margin-right: 32px;
}

.benefits__item__text {
  margin-top: 30px;
  overflow: hidden;
}

.benefits__item__text h4 {
  color: #111111;
  margin-bottom: 10px;
}

.benefits__item__text p {
  margin-bottom: 0;
}

/*---------------------
  Categories
-----------------------*/

.categories__item {
  height: 440px;
  position: relative;
  margin-bottom: 30px;
}

.categories__item__text {
  background: #ffffff;
  text-align: center;
  padding: 15px 0;
  position: absolute;
  left: 15px;
  bottom: 15px;
  width: calc(100% - 30px);
}

.categories__item__text h4 {
  color: #111111;
}

.categories__item__text span {
  font-size: 14px;
  color: #444444;
  display: block;
}

/*---------------------
  Call to action
-----------------------*/

.callto__text {
  max-width: 475px;
}

.callto__text span {
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
}

.callto__text h2 {
  color: #ffffff;
  font-size: 60px;
  line-height: 1.4;
  margin-bottom: 42px;
  font-family: "Hello Santtiny";
}

.callto__text .white-btn {
  margin-left: 16px;
}

/*---------------------
  Latest
-----------------------*/

.latest {
  padding-bottom: 50px;
}

.latest__btn {
  margin-bottom: 45px;
  text-align: right;
}

/*---------------------
  Testimonial
-----------------------*/

.testimonial {
  position: relative;
  overflow: hidden;
  z-index: 1;
  //background-repeat: repeat-x(1);
}

.testimonial:before {
  position: absolute;
  left: -340px;
  top: 0;
  background-repeat: no-repeat;


  height: 442px;
  width: 402px;
  //background-image: url(../img/testimonial/left-bg.png);
  content: "";
  z-index: -1;
}

.testimonial:after {
  position: absolute;
  right: -110px;
  bottom: -48px;
  height: 488px;
  //background-repeat: no-repeat !important;
  width: 697px;
  -webkit-transform: rotate(180deg) !important;
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  //background-image: url(../img/testimonial/right-bg.png);
  content: "";
  z-index: -1;

  img {
    margin-left: 100px !important;
  }
}

.testimonial {
  background: #f7f5fa;
  padding-bottom: 0px;
  padding-top: 100px;
  background-repeat: no-repeat !important;
}

.testimonial__text {
  text-align: center;
}

.testimonial__text p {
  font-size: 1vw;
  font-style: italic;
  line-height: 1.8;
  font-family: "Libre Baskerville", serif;
  margin-top: 38px;
  margin-bottom: 30px;
}

.testimonial__text h4 {
  color: #111111;
  font-size: 20px;
  margin-bottom: 8px;
}

.testimonial__text span {
  color: #888888;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonial__slider.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.testimonial__slider.owl-carousel .owl-nav button {
  height: 50px;
  width: 50px;
  background: #ffffff;
  border-radius: 50%;
  font-size: 30px;
  color: #888888;
  line-height: 50px;
  text-align: center;
  position: absolute;
  left: 50px;
  top: 50%;
  margin-top: -25px;
}

.testimonial__slider.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: 50px;
}

/*---------------------
  Instagram
-----------------------*/

.instagram {
  padding-top: 10px;
}

.instagram__post {
  margin-left: -5px;
  margin-right: -15px;
}

.instagram__post__item {
  height: 372px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: calc(20% - 10px);
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.instagram__post__item:hover .instagram__post__item__hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.instagram__post__item__hover {
  background: #ffffff;
  padding: 20px 75px 16px;
  text-align: center;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.instagram__post__item__hover i {
  font-size: 30px;
  color: #f45d96;
}

.instagram__post__item__hover h6 {
  color: #111111;
  margin-top: 8px;
}

.instagram__post__item__hover h6 a {
  color: #111111;
  text-decoration: underline;
}

/*---------------------
  Footer
-----------------------*/

.footer {
  padding-top: 30px;
}

.footer__logo a {
  display: inline-block;
}

.footer__top {
  padding-bottom: 35px;
}

.footer__top__text p {
  margin-bottom: 0;
}

.footer__top__social a {
  color: #111111;
  font-size: 18px;
  margin-right: 20px;
}

.footer__top__social a:last-child {
  margin-right: 0;
}

.footer__options {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}

.footer__widget {
  padding: 50px 0 45px;
}

.footer__widget.footer__widget--address {
  padding-left: 60px;
}

.footer__widget h4 {
  color: #111111;
  margin-bottom: 18px;
}

.footer__widget ul li {
  list-style: none;
  line-height: 36px;
  color: #444444;
  font-size: 15px;
}

.footer__widget ul li a {
  font-size: 15px;
  color: #444444;
}

.footer__newslatter {
  padding: 50px 65px;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}

.footer__newslatter h4 {
  color: #111111;
  margin-bottom: 18px;
}

.footer__newslatter form {
  position: relative;
}

.footer__newslatter form input {
  font-size: 15px;
  color: #888888;
  height: 35px;
  width: 100%;
  border: none;
  border-bottom: 2px solid #111111;
}

.footer__newslatter form input::-webkit-input-placeholder {
  color: #888888;
}

.footer__newslatter form input::-moz-placeholder {
  color: #888888;
}

.footer__newslatter form input:-ms-input-placeholder {
  color: #888888;
}

.footer__newslatter form input::-ms-input-placeholder {
  color: #888888;
}

.footer__newslatter form input::placeholder {
  color: #888888;
}

.footer__newslatter form button {
  font-size: 15px;
  color: #111111;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.footer__copyright {
  padding: 22px 0 22px;
}

.footer__copyright p {
  color: #111111;
  margin-bottom: 0;
}

.footer__copyright p i {
  color: #f45d96;
}

.footer__copyright p a {
  color: #f45d96;
}

/*---------------------
  About
-----------------------*/

.about {
  padding-top: 70px;
  padding-bottom: 70px;
}

.about__top__text {
  margin-bottom: 45px;
}

.about__top__text p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0;
}

.about__video {
  height: 535px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

.about__video .play-btn {
  display: inline-block;
  height: 80px;
  width: 80px;
  background: #ffffff;
  font-size: 30px;
  color: #f45d96;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
}

.about__text {
  background: #ffffff;
  -webkit-box-shadow: 0px 10px 21px rgba(99, 134, 137, 0.16);
  box-shadow: 0px 10px 21px rgba(99, 134, 137, 0.16);
  padding: 100px 90px 105px 70px;
  margin-left: -95px;
  margin-top: -70px;
  margin-bottom: 30px;
}

.about__text span {
  color: #f45d96;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 8px;
}

.about__text h2 {
  color: #111111;
  font-size: 38px;
  line-height: 1.4;
  margin-bottom: 26px;
}

.about__text p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;
}

/*---------------------
  Team
-----------------------*/

.team {
  padding-bottom: 60px;
}

.team__btn {
  margin-bottom: 45px;
  text-align: right;
}

.team__item {
  -webkit-box-shadow: 0px 10px 21px rgba(99, 134, 137, 0.16);
  box-shadow: 0px 10px 21px rgba(99, 134, 137, 0.16);
  margin-bottom: 30px;
}

.team__item__pic img {
  min-width: 100%;
}

.team__item__text {
  text-align: center;
  padding: 25px 20px 25px;
}

.team__item__text h4 {
  color: #111111;
}

.team__item__text span {
  color: #f45d96;
  display: block;
  margin-bottom: 18px;
}

.team__item__text .team__item__social a {
  font-size: 20px;
  color: #888888;
  margin-right: 16px;
}

.team__item__text .team__item__social a:last-child {
  margin-right: 0;
}

/*---------------------
  Breadcrumb
-----------------------*/

.breadcrumb-option {
  padding: 52px 0 60px;
}

.breadcrumb__text h2 {
  font-size: 50px;
  color: #111111;
}

.breadcrumb__links a {
  font-size: 16px;
  color: #f45d96;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 28px;
  position: relative;
}

.breadcrumb__links a:after {
  position: absolute;
  right: -18px;
  top: -1px;
  content: "|";
  color: #aeaeae;
}

.breadcrumb__links span {
  font-size: 16px;
  color: #111111;
  text-transform: uppercase;
  display: inline-block;
}

/*---------------------
  Product
-----------------------*/

.arrival {
  padding-top: 0;
}

.arrival .section-title {
  margin-bottom: 20px;
}

.filter__controls {
  margin-bottom: 40px;
}

.filter__controls li {
  font-size: 16px;
  color: #111111;
  display: inline-block;
  list-style: none;
  padding: 3px 10px 2px;
  margin-right: 15px;
  cursor: pointer;
  border: 2px solid transparent;
}

.filter__controls li:last-child {
  margin-right: 0;
}

.filter__controls li.active {
  border: 2px solid #f45d96;
}

.d-col {
  max-width: 20%;
  width: 100%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}

.product__item {
  overflow: hidden;
  margin-bottom: 35px;
}

.product__item:hover .product__item__pic .product__item__hover {
  bottom: 30px;
}

.product__item:hover .product__item__text .price {
  left: -500px;
  opacity: 0;
}

.product__item:hover .product__item__text .cart-btn {
  bottom: 2px;
  opacity: 1;
}

.product__item__pic {
  background-position: center center;
  position: relative;
  overflow: hidden;
  height: 344px;
}

.product__item__pic .label {
  font-size: 13px;
  font-weight: 700;
  color: #ffffff;
  background: black;
  display: inline-block;
  padding: 2px 8px 1px;
  text-transform: uppercase;
  position: absolute;
  left: 15px;
  top: 15px;
}

.product__item__pic .product__item__hover {
  position: absolute;
  left: 0;
  bottom: -300px;
  width: 100%;
  text-align: center;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.product__item__pic .product__item__hover li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}

.product__item__pic .product__item__hover li:last-child {
  margin-right: 0;
}

.product__item__pic .product__item__hover li:hover a {
  background: #f45d96;
}

.product__item__pic .product__item__hover li:hover a span {
  color: #ffffff;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.product__item__pic .product__item__hover li a {
  display: block;
  height: 40px;
  width: 40px;
  background: #ffffff;
  font-size: 18px;
  color: #111111;
  line-height: 42px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
  -webkit-box-shadow: 0px 3px 6px #ECF2E6;
  box-shadow: 0px 3px 6px #ECF2E6;
}

.product__item__pic .product__item__hover li a span {
  position: relative;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  display: inline-block;
}

.product__item__text {
  text-align: center;
  padding-top: 24px;
  position: relative;
}

.product__item__text h5 {
  margin-bottom: 8px;
}

.product__item__text h5 a {
  color: #111111;
}

.product__item__text .price {
  color: #111111;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  left: 0;
  -webkit-transition: all, 0.6s;
  -o-transition: all, 0.6s;
  transition: all, 0.6s;
}

.product__item__text .cart-btn {
  font-size: 14px;
  color: #f45d96;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  bottom: -50px;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.view__all.view__all--portfolio a {
  border-color: #f45d96;
}

.view__all a {
  display: inline-block;
  font-size: 15px;
  color: #111111;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 2px;
  border-bottom: 2px solid #111111;
}

.product__widget {
  margin-bottom: 20px;
}

.product__widget__text i {
  display: inline-block;
  height: 37px;
  width: 37px;
  background: #f2f2f2;
  font-size: 16px;
  color: #111111;
  line-height: 37px;
  text-align: center;
  margin-right: 20px;
}

.product__widget__text p {
  margin-bottom: 0;
  color: #111111;
  display: inline-block;
}

.product__widget__filter .nice-select {
  height: 37px;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  padding-left: 30px;
  line-height: 37px;
  float: right;
  width: 246px;
}

.product__widget__filter .nice-select:after {
  height: 8px;
  width: 8px;
  border-bottom: 2px solid #888888;
  border-right: 2px solid #888888;
  right: 20px;
}

.product__widget__filter .nice-select .list {
  margin-top: 0;
  border-radius: 0;
  width: 100%;
}

.product__widget__filter .nice-select span {
  font-size: 16px;
  color: #888888;
}

/*---------------------
  Product Details
-----------------------*/

.product-details {
  padding-bottom: 60px;
}

.product__details__content {
  padding-bottom: 60px;
  border-bottom: 1px solid #e1e1e1;
}

.product__details__pic {
  margin-right: -20px;
  margin-bottom: 40px;
  overflow: hidden;
}

.product__details__pic__item {
  width: calc(83% - 20px);
  float: left;
  margin-right: 20px;
  height: 720px;
}

.product__details__pic__item img {
  min-width: 100%;
  height: 50%;
}

.product__details__thumb {
  width: calc(17% - 20px);
  float: left;
  height: 720px;
  overflow: hidden;
}

.product__details__thumb .pt__item:last-child img {
  margin-bottom: 0;
}

.product__details__thumb img {
  min-width: 100%;
  margin-bottom: 20px;
  height: 100%;
  cursor: pointer;
}

.product__details__text {
  position: relative;
  padding-left: 40px;
  margin-bottom: 40px;
}

.product__details__title {
  margin-bottom: 40px;
}

.product__details__title span {
  color: #f45d96;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.product__details__title h3 {
  color: #111111;
  margin-bottom: 10px;
  font-family: 'Always Forever', sans-serif;
  font-size: 75px;
}

.product__details__title ul li {
  list-style: none;
  display: inline-block;
  font-size: 14px;
  color: #111111;
  position: relative;
  margin-right: 35px;
}

.product__details__title ul li:last-child {
  margin-right: 0;
}

.product__details__title ul li:last-child:after {
  display: none;
}

.product__details__title ul li:after {
  position: absolute;
  right: -23px;
  top: -1px;
  content: "|";
  color: #888888;
}

.product__details__title ul li .rating i {
  display: inline-block;
  color: #ECBA0B;
  margin-right: -4px;
}

.product__details__price {
  color: #f45d96;
  font-size: 30px;
  font-weight: 700;
  position: absolute;
  right: 0;
  top: 0;
}

.product__details__widget {
  margin-bottom: 45px;
}

.product__details__widget li {
  list-style: none;
  font-size: 16px;
  color: #888888;
  line-height: 30px;
}

.product__details__widget li span {
  color: #111111;
}

.product__details__btns {
  //overflow: hidden;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 50px 0;
  margin-bottom: 40px;
}

.product__details__btns .quantity {
  float: left;
  margin-right: 20px;
}

.product__details__btns .quantity .pro-qty {
  //width: 170px;
  height: 50px;
  border: 1px solid #e1e1e1;
  border-radius: 50px;
  padding: 0 25px;
}

.product__details__btns .quantity .pro-qty input {
  font-size: 20px;
  color: #111111;
  font-weight: 700;
  float: left;
  text-align: center;
  border: none;
  background: transparent;
  width: 94px;
  height: 48px;
}

.product__details__btns .quantity .pro-qty .qtybtn {
  font-size: 24px;
  color: #888888;
  float: left;
  height: 100%;
  line-height: 40px;
  cursor: pointer;
}

.product__details__btns .add_btn {
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  background: #111111;
  position: relative;
  padding: 14px 30px 12px 70px;
  border-radius: 50px;
  margin-right: 16px;
  float: left;
}

.product__details__btns .add_btn span {
  position: absolute;
  left: -1px;
  top: 0;
  height: 100%;
  padding: 0 16px;
  background: #f45d96;
  border-radius: 50%;
  line-height: 42px;
}

.product__details__btns .heart_btn {
  padding: 15px 14px;
  background: #f2f2f2;
  border-radius: 50%;
  float: left;
}

.product__details__desc .nav {
  border-bottom: none;
  margin-bottom: 30px;
}

.product__details__desc .nav-item {
  margin-right: 60px;
  position: relative;

  .nav-link {
    font-family: "Kitchen Home", sans-serif;
    font-size: 25px;
    font-weight: bold;

    &:hover {
      cursor: pointer;
    }
  }

}

.product__details__desc .nav-item:after {
  position: absolute;
  right: -35px;
  top: 4px;
  content: "/";
  color: #888888;

}

.product__details__desc .nav-item:last-child {
  margin-right: 0;
}

.product__details__desc .nav-item:last-child:after {
  display: none;
}

.product__details__desc .nav-link {
  font-size: 20px;
  font-family: "Libre Baskerville", serif;
  color: #888888;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
}

.product__details__desc .nav-link.active {
  color: #111111;
  background-color: transparent3;
  border-color: transparent;
}

.product__details__desc .tab-content p {
  margin-bottom: 0;
}

.related__products {
  padding-top: 60px;
}

/*---------------------
  Services
-----------------------*/

.services {
  padding-bottom: 70px;
}

.services .section-title {
  margin-bottom: 75px;
}

.services-page {
  padding-bottom: 90px;
}

.services__item {
  text-align: center;
  border: 1px solid #f5deef;
  padding: 60px 75px 35px;
  position: relative;
  margin-bottom: 30px;
}

.services__item .services__item__icon {
  position: absolute;
  left: 0;
  top: -35px;
  right: 0;
  margin: 0 auto;
  width: 100px;
  text-align: center;
  background: #ffffff;
}

.services__item h4 {
  color: #111111;
  margin-bottom: 15px;
}

.services__item p {
  margin-bottom: 0;
}

.services__sidebar__tab {
  padding-top: 35px;
}

.services__sidebar__tab .nav {
  display: initial;
}

.services__sidebar__tab .nav-tabs {
  border-bottom: none;
}

.services__sidebar__tab .nav-item:last-child .nav-link .services__item {
  margin-bottom: 0;
}

.services__sidebar__tab .nav-link {
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
}

.services__sidebar__tab .nav-link.active .services__item {
  border-color: #f45d96;
}

.services__sidebar__tab .nav-link .services__item {
  margin-bottom: 75px;
}

.services__tab__content {
  padding-left: 60px;
}

.services__tab__video {
  height: 432px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

.services__tab__video .play-btn {
  display: inline-block;
  height: 80px;
  width: 80px;
  background: #ffffff;
  font-size: 30px;
  color: #f45d96;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
}

.services__tab__details__title {
  margin-top: -87px;
  margin-bottom: 36px;
}

.services__tab__details__title h2 {
  font-size: 50px;
  color: #111111;
  display: inline-block;
  background: #ffffff;
  padding-top: 10px;
  padding-right: 25px;
  margin-bottom: 18px;
}

.services__tab__details__title span {
  display: block;
  color: #f45d96;
  font-weight: 20;
  font-weight: 700;
  margin-bottom: 36px;
}

.services__tab__details__title p {
  margin-bottom: 0;
}

.services__details__item {
  margin-bottom: 28px;
}

.services__details__item__icon {
  float: left;
  margin-right: 20px;
}

.services__details__item__text {
  overflow: hidden;
}

.services__details__item__text p {
  margin-bottom: 0;
}

.services__tab__details__desc {
  margin-bottom: 60px;
}

.services__tab__details__desc p {
  margin-bottom: 0;
}

.services__tab__details__form {
  background: #f7f5fa;
  padding: 50px;
}

.services__tab__details__form h4 {
  color: #111111;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 25px;
}

.services__tab__details__form form input {
  width: 100%;
  height: 46px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #888888;
  padding-left: 20px;
  background: #ffffff;
  margin-bottom: 30px;
}

.services__tab__details__form form input::-webkit-input-placeholder {
  color: #888888;
}

.services__tab__details__form form input::-moz-placeholder {
  color: #888888;
}

.services__tab__details__form form input:-ms-input-placeholder {
  color: #888888;
}

.services__tab__details__form form input::-ms-input-placeholder {
  color: #888888;
}

.services__tab__details__form form input::placeholder {
  color: #888888;
}

.services__tab__details__form form .nice-select {
  height: 46px;
  float: none;
  display: block;
  border: 1px solid #e1e1e1;
  background: #ffffff;
  margin-bottom: 30px;
  border-radius: 0;
  line-height: 46px;
  padding-left: 20px;
}

.services__tab__details__form form .nice-select:after {
  height: 8px;
  width: 8px;
  border-bottom: 2px solid #888888;
  border-right: 2px solid #888888;
  right: 20px;
}

.services__tab__details__form form .nice-select .list {
  margin-top: 0;
  border-radius: 0;
  width: 100%;
}

.services__tab__details__form form .nice-select span {
  font-size: 16px;
  color: #888888;
}

.services__tab__details__form form textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #888888;
  padding-left: 20px;
  background: #ffffff;
  margin-bottom: 24px;
  padding-top: 12px;
  resize: none;
}

.services__tab__details__form form textarea::-webkit-input-placeholder {
  color: #888888;
}

.services__tab__details__form form textarea::-moz-placeholder {
  color: #888888;
}

.services__tab__details__form form textarea:-ms-input-placeholder {
  color: #888888;
}

.services__tab__details__form form textarea::-ms-input-placeholder {
  color: #888888;
}

.services__tab__details__form form textarea::placeholder {
  color: #888888;
}

/*---------------------
  Cart
-----------------------*/

.cart__table {
  padding-right: 40px;
}

.cart__table table {
  width: 100%;
}

.cart__table thead {
  border-bottom: 1px solid #e1e1e1;
}

.cart__table thead tr th {
  font-size: 24px;
  color: #111111;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  padding-bottom: 35px;
}

.cart__table tbody tr {
  border-bottom: 1px solid #e1e1e1;
}

.cart__table tbody tr td {
  padding-top: 35px;
  padding-bottom: 35px;
}

.cart__table tbody tr td.cart__item {
  width: 480px;
}

.cart__table tbody tr td.cart__item .cart__item__pic {
  float: left;
  margin-right: 30px;
}

.cart__table tbody tr td.cart__item .cart__item__text {
  overflow: hidden;
  padding-top: 10px;
}

.cart__table tbody tr td.cart__item .cart__item__text h4 {
  color: #111111;
  margin-bottom: 10px;
}

.cart__table tbody tr td.cart__item .cart__item__text span {
  color: #111111;
  font-size: 20px;
  display: block;
  font-weight: 700;
}

.cart__quantity {
  width: 200px;
}

.cart__quantity .quantity .pro-qty {
  width: 100px;
}

.cart__quantity .quantity .pro-qty input {
  font-size: 20px;
  color: #111111;
  font-weight: 700;
  float: left;
  text-align: center;
  border: none;
  background: transparent;
  width: 80px;
  height: 48px;
}

.cart__quantity .quantity .pro-qty .qtybtn {
  font-size: 16px;
  color: #888888;
  float: left;
  height: 100%;
  line-height: 46px;
  cursor: pointer;
}

.cart__price {
  font-size: 20px;
  color: #111111;
  font-weight: 700;
  width: 200px;
}

.cart__close {
  font-size: 20px;
  color: #f45d96;
}

.cart__btn {
  padding-top: 35px;
}

.cart__btn__continue a {
  font-size: 16px;
  color: #0d0d0d;
  font-weight: 700;
  text-transform: uppercase;
}

.cart__btn__continue a span {
  font-size: 15px;
  color: #f45d96;
  margin-right: 5px;
}

.cart__btn__right {
  text-align: right;
}

.cart__btn__right a {
  font-size: 16px;
  color: #0d0d0d;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 65px;
}

.cart__btn__right a:last-child {
  margin-right: 0;
}

.cart__btn__right a span {
  font-size: 15px;
  color: #f45d96;
  margin-right: 5px;
}

.cart__coupon {
  background: #f7f5fa;
  padding: 30px;
  margin-bottom: 50px;
}

.cart__coupon p {
  font-size: 14px;
  margin-bottom: 12px;
}

.cart__coupon form {
  position: relative;
}

.cart__coupon form input {
  height: 46px;
  width: 100%;
  border: 1px solid #e1e1e1;
  font-size: 14px;
  color: #888888;
  padding-left: 20px;
}

.cart__coupon form input::-webkit-input-placeholder {
  color: #888888;
}

.cart__coupon form input::-moz-placeholder {
  color: #888888;
}

.cart__coupon form input:-ms-input-placeholder {
  color: #888888;
}

.cart__coupon form input::-ms-input-placeholder {
  color: #888888;
}

.cart__coupon form input::placeholder {
  color: #888888;
}

.cart__coupon form button {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: #111111;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 30px;
}

.cart__total {
  background: #f7f5fa;
  padding: 30px;
}

.cart__total h4 {
  color: #111111;
  margin-bottom: 36px;
}

.cart__total ul {
  margin-bottom: 28px;
}

.cart__total ul li {
  list-style: none;
  font-size: 16px;
  color: #888888;
  line-height: 36px;
  overflow: hidden;
}

.cart__total ul li span {
  font-weight: 700;
  color: #111111;
  float: right;
}

.cart__total ul li:last-child span {
  color: #f45d96;
}

.cart__total a {
  color: #111111;
  display: block;
  color: #ffffff;
  background: #111111;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 14px 20px 12px;
  text-align: center;
}

/*---------------------
  Checkout
-----------------------*/

.checkout__form__top {
  padding-bottom: 25px;
}

.checkout__form__top h4 {
  font-size: 26px;
  color: #111111;
}

.checkout__form__top h6 {
  color: #444444;
  text-align: center;
}

.checkout__form__top h6 a {
  color: #f45d96;
}

.checkout__form form .input__top {
  margin-bottom: 38px;
}

.checkout__form form .input__top input {
  height: 46px;
  width: 100%;
  padding-left: 20px;
  font-size: 16px;
  color: #888888;
  border: 1px solid #e1e1e1;
  margin-bottom: 15px;
}

.checkout__form form .input__top input::-webkit-input-placeholder {
  color: #888888;
}

.checkout__form form .input__top input::-moz-placeholder {
  color: #888888;
}

.checkout__form form .input__top input:-ms-input-placeholder {
  color: #888888;
}

.checkout__form form .input__top input::-ms-input-placeholder {
  color: #888888;
}

.checkout__form form .input__top input::placeholder {
  color: #888888;
}

.checkout__form form label {
  position: relative;
  font-size: 16px;
  color: #444444;
  cursor: pointer;
  padding-left: 30px;
  margin-bottom: 0;
}

.checkout__form form label input {
  position: absolute;
  visibility: hidden;
}

.checkout__form form label input:checked ~ .checkmark {
  background: #f45d96;
  border-color: #f45d96;
}

.checkout__form form label input:checked ~ .checkmark:after {
  opacity: 1;
}

.checkout__form form label .checkmark {
  position: absolute;
  left: 0;
  top: 6px;
  height: 12px;
  width: 12px;
  border: 1px solid #444444;
  border-radius: 2px;
  content: "";
}

.checkout__form form label .checkmark:after {
  position: absolute;
  left: 0px;
  top: -1px;
  width: 13px;
  height: 7px;
  border: solid #ffffff;
  border-width: 2px 2px 0px 0px;
  -webkit-transform: rotate(127deg);
  -ms-transform: rotate(127deg);
  transform: rotate(127deg);
  content: "";
  opacity: 0;
}

.checkout__form .site-btn {
  width: 100%;
}

.checkout__address h4 {
  font-size: 26px;
  color: #111111;
  margin-bottom: 24px;
}

.checkout__address .input__item {
  margin-bottom: 25px;
}

.checkout__address .input__item.double__input input {
  margin-bottom: 10px;
}

.checkout__address .input__item.double__input input:last-child {
  margin-bottom: 0;
}

.checkout__address .input__item p {
  color: #444444;
  margin-bottom: 10px;
}

.checkout__address .input__item p span {
  color: #f45d96;
}

.checkout__address .input__item input {
  height: 46px;
  width: 100%;
  padding-left: 20px;
  font-size: 16px;
  color: #888888;
  border: 1px solid #e1e1e1;
}

.checkout__address .input__item input::-webkit-input-placeholder {
  color: #888888;
}

.checkout__address .input__item input::-moz-placeholder {
  color: #888888;
}

.checkout__address .input__item input:-ms-input-placeholder {
  color: #888888;
}

.checkout__address .input__item input::-ms-input-placeholder {
  color: #888888;
}

.checkout__address .input__item input::placeholder {
  color: #888888;
}

.checkout__proceed {
  background: #f7f5fa;
  padding: 30px;
}

.checkout__proceed .checkout__proceed__title {
  position: relative;
  z-index: 1;
}

.checkout__proceed .checkout__proceed__title:after {
  position: absolute;
  left: 0;
  top: 14px;
  height: 1px;
  width: 100%;
  background: #e1e1e1;
  content: "";
  z-index: -1;
}

.checkout__proceed .checkout__proceed__title h4 {
  color: #111111;
  margin-bottom: 38px;
  display: inline-block;
  background: #f7f5fa;
  padding: 0 15px 0 0;
  position: relative;
}

.checkout__proceed__coupon {
  margin-bottom: 50px;
}

.checkout__proceed__coupon p {
  font-size: 14px;
  margin-bottom: 12px;
}

.checkout__proceed__coupon .coupon__input {
  position: relative;
}

.checkout__proceed__coupon .coupon__input input {
  height: 46px;
  width: 100%;
  border: 1px solid #e1e1e1;
  font-size: 14px;
  color: #888888;
  padding-left: 20px;
}

.checkout__proceed__coupon .coupon__input input::-webkit-input-placeholder {
  color: #888888;
}

.checkout__proceed__coupon .coupon__input input::-moz-placeholder {
  color: #888888;
}

.checkout__proceed__coupon .coupon__input input:-ms-input-placeholder {
  color: #888888;
}

.checkout__proceed__coupon .coupon__input input::-ms-input-placeholder {
  color: #888888;
}

.checkout__proceed__coupon .coupon__input input::placeholder {
  color: #888888;
}

.checkout__proceed__coupon .coupon__input button {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: #111111;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 30px;
}

.checkout__proceed__product {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 22px;
  margin-bottom: 22px;
}

.checkout__proceed__product h5 {
  color: #111111;
  font-size: 20px;
  letter-spacing: 2px;
  margin-bottom: 18px;
}

.checkout__proceed__product ul li {
  list-style: none;
  font-size: 16px;
  color: #111111;
  overflow: hidden;
  line-height: 36px;
}

.checkout__proceed__product ul li span {
  font-weight: 700;
  float: right;
}

.checkout__proceed__total {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.checkout__proceed__total li {
  list-style: none;
  font-size: 20px;
  color: #111111;
  line-height: 36px;
  overflow: hidden;
  font-weight: 500;
}

.checkout__proceed__total li span {
  font-weight: 700;
  color: #111111;
  float: right;
}

.checkout__proceed__total li:last-child span {
  color: #f45d96;
}

.checkout__proceed__widget {
  margin-bottom: 40px;
}

.checkout__proceed__widget label {
  position: relative;
  font-size: 16px;
  color: #444444;
  cursor: pointer;
  padding-left: 30px;
  display: block;
  margin-bottom: 5px !important;
}

.checkout__proceed__widget label:last-child {
  margin-bottom: 0;
}

.checkout__proceed__widget label input {
  position: absolute;
  visibility: hidden;
}

.checkout__proceed__widget label input:checked ~ .checkmark {
  background: #f45d96;
  border-color: #f45d96;
}

.checkout__proceed__widget label input:checked ~ .checkmark:after {
  opacity: 1;
}

.checkout__proceed__widget label .checkmark {
  position: absolute;
  left: 0;
  top: 6px;
  height: 12px;
  width: 12px;
  border: 1px solid #444444;
  border-radius: 2px;
  content: "";
}

.checkout__proceed__widget label .checkmark:after {
  position: absolute;
  left: 0px;
  top: -1px;
  width: 13px;
  height: 7px;
  border: solid #ffffff;
  border-width: 2px 2px 0px 0px;
  -webkit-transform: rotate(127deg);
  -ms-transform: rotate(127deg);
  transform: rotate(127deg);
  content: "";
  opacity: 0;
}

/*---------------------
  Wishlist
-----------------------*/

.wishlist__table table {
  width: 100%;
}

.wishlist__table thead {
  border-bottom: 1px solid #e1e1e1;
}

.wishlist__table thead tr th {
  font-size: 24px;
  color: #111111;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  padding-bottom: 35px;
}

.wishlist__table tbody tr {
  border-bottom: 1px solid #e1e1e1;
}

.wishlist__table tbody tr td {
  padding-top: 35px;
  padding-bottom: 35px;
}

.wishlist__table tbody tr td.wishlist__item {
  width: 705px;
}

.wishlist__table tbody tr td.wishlist__item .wishlist__item__pic {
  float: left;
  margin-right: 30px;
}

.wishlist__table tbody tr td.wishlist__item .wishlist__item__text {
  overflow: hidden;
  padding-top: 35px;
}

.wishlist__table tbody tr td.wishlist__item .wishlist__item__text h4 {
  color: #111111;
}

.wishlist__price {
  font-size: 20px;
  color: #111111;
  font-weight: 700;
  width: 240px;
}

.wishlist__stock {
  font-size: 16px;
  color: #111111;
  width: 240px;
}

.wishlist__btn {
  width: 220px;
}

.wishlist__btn a {
  font-size: 16px;
  color: #111111;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: #f7f5fa;
  display: inline-block;
  padding: 14px 30px 12px;
  border-radius: 50px;
}

.wishlist__close {
  font-size: 20px;
  color: #f45d96;
}

/*---------------------
  Portfolio
-----------------------*/

.portfolio__item {
  height: 290px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 50px;
}

.portfolio__item:hover .portfolio__item__text {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.portfolio__item.portfolio__item__medium {
  height: 436px;
}

.portfolio__item.portfolio__item__large {
  height: 580px;
}

.portfolio__item__text {
  background: #ffffff;
  display: inline-block;
  padding: 15px 40px 15px;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  text-align: center;
}

.portfolio__item__text h4 {
  color: #111111;
  margin-bottom: 4px;
}

.portfolio__item__text span {
  color: #f45d96;
  display: block;
}

/*---------------------
  Blog
-----------------------*/

.blog__item {
  -webkit-box-shadow: 0px 10px 21px rgba(99, 133, 136, 0.16);
  box-shadow: 0px 10px 21px rgba(99, 133, 136, 0.16);
  margin-bottom: 50px;
}

.blog__item:hover .blog__item__text .label span {
  background: #f45d96;
}

.blog__item:hover .blog__item__text h4 a {
  text-decoration: underline;
}

.blog__item__pic img {
  height: 100%;
  min-width: 100%;
}

.blog__item__text {
  text-align: center;
  position: relative;
  padding: 38px 35px 30px;
}

.blog__item__text .label {
  position: absolute;
  left: 0;
  top: -13px;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.blog__item__text .label span {
  color: #ffffff;
  background: #111111;
  font-weight: 700;
  text-transform: uppercase;
  padding: 4px 10px 2px;
  display: inline-block;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.blog__item__text h4 {
  margin-bottom: 12px;
}

.blog__item__text h4 a {
  color: #111111;
  line-height: 31px;
}

.blog__item__text p {
  margin-bottom: 10px;
}

.blog__item__text span {
  font-size: 14px;
  color: #888888;
  font-weight: 500;
  text-transform: uppercase;
}

/*---------------------
  Blog Details
-----------------------*/

.blog-hero {
  padding: 168px 0;
}

.blog__hero__text {
  background: #ffffff;
  padding: 40px 90px;
  text-align: center;
}

.blog__hero__text .label {
  color: #ffffff;
  background: #f45d96;
  font-weight: 700;
  text-transform: uppercase;
  padding: 4px 10px 2px;
  display: inline-block;
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.blog__hero__text h2 {
  font-size: 40px;
  color: #111111;
  line-height: 50px;
  margin-top: 18px;
  margin-bottom: 16px;
}

.blog__hero__text ul li {
  font-size: 14px;
  color: #888888;
  font-weight: 500;
  margin-right: 25px;
  list-style: none;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
}

.blog__hero__text ul li:after {
  position: absolute;
  right: -16px;
  top: -1px;
  content: "|";
  color: #888888;
}

.blog__hero__text ul li:last-child {
  margin-right: 0;
}

.blog__hero__text ul li:last-child:after {
  display: none;
}

.blog__hero__text ul li span {
  color: #111111;
}

.blog__details__top__text {
  margin-bottom: 45px;
}

.blog__details__top__text p {
  color: #111111;
  line-height: 30px;
  margin-bottom: 30px;
}

.blog__details__top__text p:last-child {
  margin-bottom: 0;
}

.blog__details__top__text .quote {
  border-bottom: 1px dashed #f45d96;
  border-top: 1px dashed #f45d96;
  padding: 30px 0;
  padding-right: 100px;
  margin-bottom: 40px;
}

.blog__details__top__text .quote p {
  font-size: 24px;
  line-height: 35px;
  text-transform: uppercase;
}

.blog__details__item.normal__item .blog__details__top__text {
  margin-bottom: 40px;
}

.blog__details__item.normal__item .blog__details__item__pic {
  margin-bottom: 40px;
}

.blog__details__item__text {
  margin-bottom: 50px;
}

.blog__details__item__text h4 {
  color: #111111;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.blog__details__item__text p {
  color: #111111;
  line-height: 30px;
  margin-bottom: 30px;
}

.blog__details__item__text p:last-child {
  margin-bottom: 0;
}

.blog__details__item__pic {
  margin-bottom: 50px;
}

.blog__details__item__pic img {
  min-width: 100%;
}

.blog__details__widget {
  border-top: 1px solid #e1e1e1;
  padding-top: 40px;
  margin-bottom: 40px;
}

.blog__details__tags span {
  font-size: 16px;
  color: #111111;
  display: inline-block;
  margin-right: 15px;
}

.blog__details__tags a {
  color: #111111;
  font-size: 16px;
  border: 1px solid #e1e1e1;
  padding: 7px 15px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;
}

.blog__details__tags a:last-child {
  margin-right: 0;
}

.blog__details__share {
  text-align: right;
}

.blog__details__share span {
  font-size: 16px;
  color: #111111;
  display: inline-block;
  margin-right: 15px;
}

.blog__details__share a {
  color: #111111;
  font-size: 18px;
  margin-right: 22px;
}

.blog__details__share a:last-child {
  margin-right: 0;
}

.blog__details__btns {
  background: #f7f5fa;
  margin-bottom: 65px;
}

.blog__details__btns__item {
  padding: 45px 50px;
}

.blog__details__btns__item.blog__details__btns__item--next {
  text-align: right;
}

.blog__details__btns__item span {
  font-size: 16px;
  color: #888888;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 12px;
}

.blog__details__btns__item h4 {
  color: #111111;
  letter-spacing: 1px;
  line-height: 30px;
}

.blog__details__comment {
  margin-bottom: 65px;
}

.blog__details__comment h4 {
  color: #111111;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.blog__details__comment__item {
  position: relative;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 32px;
  margin-bottom: 40px;
}

.blog__details__comment__item h5 {
  font-size: 20px;
  color: #111111;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

.blog__details__comment__item h5 span {
  font-size: 16px;
  color: #888888;
}

.blog__details__comment__item h6 {
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
  color: #111111;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.blog__details__comment__item p {
  color: #111111;
  margin-bottom: 0;
}

.blog__details__comment__item a {
  color: #888888;
  font-size: 16px;
  letter-spacing: 1px;
  position: absolute;
  right: 0;
  display: inline-block;
  top: 0;
}

.all__comment a {
  color: #f45d96;
  letter-spacing: 1px;
  font-size: 16px;
  border-bottom: 1px solid #f45d96;
}

.blog__details__form h4 {
  color: #111111;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.blog__details__form form input {
  font-size: 16px;
  color: #888888;
  height: 46px;
  width: 100%;
  border: 1px solid #e1e1e1;
  padding-left: 20px;
  letter-spacing: 1px;
  margin-bottom: 25px;
}

.blog__details__form form input::-webkit-input-placeholder {
  color: #888888;
}

.blog__details__form form input::-moz-placeholder {
  color: #888888;
}

.blog__details__form form input:-ms-input-placeholder {
  color: #888888;
}

.blog__details__form form input::-ms-input-placeholder {
  color: #888888;
}

.blog__details__form form input::placeholder {
  color: #888888;
}

.blog__details__form form textarea {
  font-size: 16px;
  color: #888888;
  height: 100px;
  width: 100%;
  border: 1px solid #e1e1e1;
  padding-left: 20px;
  padding-top: 12px;
  letter-spacing: 1px;
  resize: none;
  margin-bottom: 20px;
}

.blog__details__form form textarea::-webkit-input-placeholder {
  color: #888888;
}

.blog__details__form form textarea::-moz-placeholder {
  color: #888888;
}

.blog__details__form form textarea:-ms-input-placeholder {
  color: #888888;
}

.blog__details__form form textarea::-ms-input-placeholder {
  color: #888888;
}

.blog__details__form form textarea::placeholder {
  color: #888888;
}

.blog__details__form p {
  color: #111111;
  margin-bottom: 25px;
}

.blog__details__form p span {
  color: #f45d96;
}

.blog__details__categories {
  margin-bottom: 52px;
}

.blog__details__categories h4 {
  color: #111111;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.blog__details__categories ul li {
  list-style: none;
  line-height: 34px;
  overflow: hidden;
}

.blog__details__categories ul li a {
  font-size: 16px;
  color: #444444;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.blog__details__categories ul li a:hover {
  color: #f45d96;
}

.blog__details__categories ul li a:hover span {
  color: #f45d96;
}

.blog__details__categories ul li a span {
  float: right;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.blog__details__post .title {
  color: #111111;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.blog__details__post__item {
  margin-bottom: 35px;
}

.blog__details__post__item:last-child {
  margin-bottom: 0;
}

.blog__details__post__item:hover .blog__details__post__item__text h4 a {
  text-decoration: underline;
}

.blog__details__post__item img {
  min-width: 100%;
}

.blog__details__post__item .blog__details__post__item__text {
  position: relative;
  width: calc(100% - 45px);
  background: #ffffff;
  margin-top: -50px;
  padding: 28px 30px 0;
}

.blog__details__post__item .blog__details__post__item__text h4 {
  margin-bottom: 12px;
}

.blog__details__post__item .blog__details__post__item__text h4 a {
  color: #111111;
  line-height: 31px;
}

.blog__details__post__item .blog__details__post__item__text span {
  font-size: 14px;
  color: #444444;
  display: block;
  font-weight: 500;
}

/*---------------------
  Contact
-----------------------*/

.contact {
  padding-bottom: 65px;
}

.contact__widget {
  margin-bottom: 30px;
}

.contact__widget h2 {
  color: #111111;
  font-size: 40px;
  margin-bottom: 24px;
}

.contact__widget p {
  margin-bottom: 10px;
}

.contact__widget ul li {
  color: #444444;
  font-size: 15px;
  line-height: 40px;
  list-style: none;
}

.contact__widget ul li i {
  color: #f45d96;
  margin-right: 20px;
}

.contact__form {
  margin-bottom: 30px;
}

.contact__form h2 {
  color: #111111;
  font-size: 40px;
  margin-bottom: 35px;
}

.contact__form form input {
  font-size: 16px;
  color: #888888;
  height: 46px;
  width: 100%;
  border: 1px solid #e1e1e1;
  padding-left: 20px;
  margin-bottom: 25px;
}

.contact__form form input::-webkit-input-placeholder {
  color: #888888;
}

.contact__form form input::-moz-placeholder {
  color: #888888;
}

.contact__form form input:-ms-input-placeholder {
  color: #888888;
}

.contact__form form input::-ms-input-placeholder {
  color: #888888;
}

.contact__form form input::placeholder {
  color: #888888;
}

.contact__form form textarea {
  font-size: 16px;
  color: #888888;
  height: 100px;
  width: 100%;
  border: 1px solid #e1e1e1;
  padding-left: 20px;
  padding-top: 12px;
  resize: none;
  margin-bottom: 20px;
}

.contact__form form textarea::-webkit-input-placeholder {
  color: #888888;
}

.contact__form form textarea::-moz-placeholder {
  color: #888888;
}

.contact__form form textarea:-ms-input-placeholder {
  color: #888888;
}

.contact__form form textarea::-ms-input-placeholder {
  color: #888888;
}

.contact__form form textarea::placeholder {
  color: #888888;
}

.contact__map {
  height: 310px;
  margin-bottom: 30px;
}

.contact__map iframe {
  width: 100%;
}

/*--------------------------------- Responsive Media Quaries -----------------------------*/

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1449px) {
  .d-col {
    max-width: 25%;
  }
  .header__menu {
    margin-right: 30px;
  }
  .header__menu ul li {
    margin-right: 30px;
  }
  .instagram__post__item__hover {
    padding: 20px 30px 16px;
  }
  .checkout__form .site-btn {
    width: 100%;
    padding: 14px 15px;
  }
}

@media only screen and (min-width: 1450px) {
  .container {
    max-width: 1410px;
  }
}

/* Medium Device = 1200px */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__slider.owl-carousel .owl-dots {
    width: 930px;
  }
  .d-col {
    max-width: 33.33%;
  }
  .instagram__post__item__hover {
    padding: 20px 25px 16px;
  }
  .instagram__post__item {
    height: 265px;
  }
  .footer__newslatter {
    padding: 54px 25px;
  }
  .header__menu {
    margin-right: 10px;
  }
  .header__option__right__search {
    margin-right: 15px;
  }
  .header__option__right__cart ul li {
    margin-right: 15px;
  }
  .header__menu ul li {
    margin-right: 12px;
  }
  .footer__widget.footer__widget--address {
    padding-left: 20px;
  }
  .services__item {
    padding: 60px 40px 35px;
  }
  .cart__btn__right a {
    margin-right: 15px;
  }
  .cart__total a {
    letter-spacing: 0px;
  }
  .checkout__form .site-btn {
    letter-spacing: 0;
    padding: 14px 20px;
  }
  .checkout__proceed__coupon .coupon__input button {
    padding: 0 20px;
  }
  .wishlist__btn a {
    letter-spacing: 0;
    padding: 14px 25px 12px;
  }
  .product__details__pic__item img {
    height: auto;
  }
  .product__details__thumb img {
    height: auto;
  }
  .product__details__text {
    padding-left: 0;
  }
  .product__details__btns .quantity {
    margin-right: 10px;
  }
  .product__details__btns .add_btn {
    padding: 14px 30px 12px 60px;
    margin-right: 6px;
  }
  .blog__details__share a {
    margin-right: 13px;
  }
  .cart__coupon form button {
    letter-spacing: 0;
    padding: 0 20px;
  }
  .cart__table {
    padding-right: 0;
  }
  .product__details__pic__item {
    height: auto;
  }
  .product__details__thumb {
    height: auto;
  }
}

/* Tablet Device = 768px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .canvas__open {
    display: block;
    font-size: 22px;
    color: #111111;
    height: 35px;
    width: 35px;
    line-height: 37px;
    text-align: center;
    border: 1px solid #111111;
    border-radius: 2px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 28px;
    z-index: 9;
  }
  .offcanvas-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    content: "";
    z-index: 98;
    -webkit-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    visibility: hidden;
  }
  .offcanvas-menu-overlay.active {
    visibility: visible;
  }
  .offcanvas-menu-wrapper {
    position: fixed;
    left: -300px;
    width: 300px;
    height: 100%;
    background: #ffffff;
    padding: 50px 20px 30px 30px;
    display: block;
    z-index: 99;
    overflow-y: auto;
    -webkit-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    opacity: 0;
  }
  .offcanvas-menu-wrapper.active {
    opacity: 1;
    left: 0;
  }
  .slicknav_btn {
    display: none;
  }
  .slicknav_menu {
    background: transparent;
    padding: 0;
    margin-bottom: 20px;
  }
  .slicknav_nav ul {
    margin: 0;
  }
  .slicknav_nav .slicknav_row, .slicknav_nav a {
    padding: 7px 0;
    margin: 0;
    color: #111111;
    font-weight: 600;
  }
  .slicknav_nav .slicknav_arrow {
    color: #111111;
  }
  .slicknav_nav .slicknav_row:hover {
    border-radius: 0;
    background: transparent;
    color: #111111;
  }
  .slicknav_nav a:hover {
    border-radius: 0;
    background: transparent;
    color: #111111;
  }
  .slicknav_nav {
    display: block !important;
  }
  .offcanvas__logo {
    margin-bottom: 25px;
  }
  .offcanvas__logo a {
    display: inline-block;
  }
  .offcanvas__search__option {
    margin-bottom: 20px;
  }
  .offcanvas__widget {
    margin-bottom: 30px;
  }
  .offcanvas__widget ul {
    display: inline-block;
  }
  .offcanvas__widget ul li {
    list-style: none;
    display: inline-block;
    margin-right: 25px;
  }
  .offcanvas__widget ul li:last-child {
    margin-right: 0;
  }
  .offcanvas__widget ul li img {
    display: inline-block;
    margin-right: 5px;
  }
  .offcanvas__widget ul li span {
    color: #111111;
    font-size: 14px;
    display: inline-block;
  }
  .offcanvas__widget .price {
    display: inline-block;
    color: #f45d96;
    font-size: 14px;
    font-weight: 700;
  }
  .header .container {
    position: relative;
  }
  .header__options {
    display: none;
  }
  .hero__slider.owl-carousel .owl-dots {
    width: 690px;
  }
  .about__text {
    padding: 50px 90px 50px 70px;
    margin-left: 0;
    margin-top: 0;
  }
  .filter__controls li {
    margin-right: 8px;
  }
  .d-col {
    max-width: 50%;
  }
  .instagram__post__item {
    width: calc(50% - 10px);
  }
  .testimonial__slider.owl-carousel .owl-nav {
    text-align: center;
  }
  .testimonial__slider.owl-carousel .owl-nav button {
    position: relative;
    top: 0;
    margin: 40px 5px 0;
  }
  .testimonial__slider.owl-carousel .owl-nav button {
    left: 0;
  }
  .testimonial__slider.owl-carousel .owl-nav button.owl-next {
    right: 0;
  }
  .testimonial:before, .testimonial:after {
    display: none;
  }
  .footer__newslatter {
    padding: 0;
    border-left: none;
    border-right: none;
    margin-bottom: 30px;
  }
  .footer__widget {
    padding: 0;
    margin-bottom: 30px;
  }
  .footer__widget.footer__widget--address {
    padding-left: 0;
  }
  .footer__options {
    padding-top: 50px;
  }
  .services__item {
    margin-bottom: 70px;
  }
  .services {
    padding-bottom: 30px;
  }
  .services__tab__content {
    padding-left: 0;
  }
  .services__sidebar__tab {
    margin-bottom: 50px;
  }
  .cart__btn__right a {
    margin-right: 15px;
  }
  .cart__table {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .checkout__proceed {
    margin-top: 35px;
  }
  .product__details__text {
    padding-left: 0;
  }
  .blog__details__sidebar {
    padding-top: 40px;
  }
}

/* Wide Mobile = 480px */

@media only screen and (max-width: 767px) {
  .canvas__open {
    display: block;
    font-size: 22px;
    color: #111111;
    height: 35px;
    width: 35px;
    line-height: 37px;
    text-align: center;
    border: 1px solid #111111;
    border-radius: 2px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 28px;
    z-index: 9;
  }
  .offcanvas-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    content: "";
    z-index: 98;
    -webkit-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    visibility: hidden;
  }
  .offcanvas-menu-overlay.active {
    visibility: visible;
  }
  .offcanvas-menu-wrapper {
    position: fixed;
    left: -300px;
    width: 300px;
    height: 100%;
    background: #ffffff;
    padding: 50px 20px 30px 30px;
    display: block;
    z-index: 99;
    overflow-y: auto;
    -webkit-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    opacity: 0;
  }
  .offcanvas-menu-wrapper.active {
    opacity: 1;
    left: 0;
  }
  .slicknav_btn {
    display: none;
  }
  .slicknav_menu {
    background: transparent;
    padding: 0;
    margin-bottom: 20px;
  }
  .slicknav_nav ul {
    margin: 0;
  }
  .slicknav_nav .slicknav_row, .slicknav_nav a {
    padding: 7px 0;
    margin: 0;
    color: #111111;
    font-weight: 600;
  }
  .slicknav_nav .slicknav_arrow {
    color: #111111;
  }
  .slicknav_nav .slicknav_row:hover {
    border-radius: 0;
    background: transparent;
    color: #111111;
  }
  .slicknav_nav a:hover {
    border-radius: 0;
    background: transparent;
    color: #111111;
  }
  .slicknav_nav {
    display: block !important;
  }
  .offcanvas__logo {
    margin-bottom: 25px;
  }
  .offcanvas__logo a {
    display: inline-block;
  }
  .offcanvas__search__option {
    margin-bottom: 20px;
  }
  .offcanvas__widget {
    margin-bottom: 30px;
  }
  .offcanvas__widget ul {
    display: inline-block;
  }
  .offcanvas__widget ul li {
    list-style: none;
    display: inline-block;
    margin-right: 25px;
  }
  .offcanvas__widget ul li:last-child {
    margin-right: 0;
  }
  .offcanvas__widget ul li img {
    display: inline-block;
    margin-right: 5px;
  }
  .offcanvas__widget ul li span {
    color: #111111;
    font-size: 14px;
    display: inline-block;
  }
  .offcanvas__widget .price {
    display: inline-block;
    color: #f45d96;
    font-size: 14px;
    font-weight: 700;
  }
  .header .container {
    position: relative;
  }
  .header__options {
    display: none;
  }
  .hero__slider.owl-carousel .owl-dots {
    width: 100%;
    padding: 0 15px;
  }
  .about__text {
    padding: 50px 70px 50px 70px;
    margin-left: 0;
    margin-top: 0;
  }
  .d-col {
    max-width: 100%;
  }
  .filter__controls li {
    margin-bottom: 10px;
  }
  .latest__btn {
    text-align: left;
  }
  .testimonial__slider.owl-carousel .owl-nav button {
    left: 0;
  }
  .testimonial__slider.owl-carousel .owl-nav button.owl-next {
    right: 0;
  }
  .instagram__post__item {
    width: calc(50% - 10px);
  }
  .footer__logo {
    margin-bottom: 30px;
  }
  .footer__top__text {
    margin-bottom: 30px;
  }
  .footer__newslatter {
    padding: 0;
    border-left: none;
    border-right: none;
    margin-bottom: 30px;
  }
  .footer__widget {
    padding: 0;
    margin-bottom: 30px;
  }
  .footer__widget.footer__widget--address {
    padding-left: 0;
  }
  .footer__options {
    padding-top: 50px;
  }
  .services__item {
    margin-bottom: 70px;
    padding: 60px 10px 35px;
  }
  .services {
    padding-bottom: 30px;
  }
  .team__btn {
    text-align: left;
  }
  .services__tab__content {
    padding-left: 0;
  }
  .services__sidebar__tab {
    margin-bottom: 50px;
  }
  .product__widget__filter {
    margin-top: 20px;
  }
  .product__widget__filter .nice-select {
    float: none;
  }
  .cart__table {
    overflow: auto;
  }
  .cart__table tbody tr td.cart__item .cart__item__pic {
    float: none;
    margin-right: 0;
  }
  .cart__table tbody tr td.cart__item .cart__item__text {
    padding-top: 20px;
  }
  .cart__btn__continue {
    margin-bottom: 20px;
  }
  .cart__table {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .checkout__form__top h6 {
    text-align: left;
    margin-top: 20px;
  }
  .checkout__proceed {
    margin-top: 35px;
  }
  .wishlist__table tbody tr td.wishlist__item .wishlist__item__pic {
    float: none;
    margin-right: 0;
  }
  .wishlist__table tbody tr td.wishlist__item .wishlist__item__text {
    padding-top: 20px;
  }
  .wishlist__table {
    overflow: auto;
  }
  .product__details__pic__item img {
    height: auto;
  }
  .product__details__thumb img {
    height: auto;
  }
  .product__details__pic__item {
    height: auto;
  }
  .product__details__thumb {
    height: auto;
  }
  .product__details__text {
    padding-left: 0;
  }
  .product__details__btns .quantity {
    margin-bottom: 20px;
  }
  .product__details__btns .add_btn {
    margin-bottom: 20px;
    overflow: hidden;
  }
  .product__details__btns .heart_btn {
    margin-bottom: 20px;
    overflow: hidden;
  }
  .blog__details__share {
    text-align: left;
    margin-top: 20px;
  }
  .blog__details__sidebar {
    padding-top: 40px;
  }
  .blog__details__top__text .quote {
    padding-right: 0;
  }
  .filter__controls li {
    margin-right: 10px;
  }
  .cart__btn__right {
    text-align: left;
  }
  .search-model-form input {
    width: 100%;
    font-size: 20px;
  }
  .testimonial__slider.owl-carousel .owl-nav {
    text-align: center;
  }
  .testimonial__slider.owl-carousel .owl-nav button {
    position: relative;
    top: 0;
    margin: 40px 5px 0;
  }
  .testimonial:before, .testimonial:after {
    display: none;
  }
}

@media only screen and (min-width: 576px) {
  .hero__slider.owl-carousel .owl-dots {
    width: 530px;
  }
}

/* Small Device = 320px */

@media only screen and (max-width: 479px) {
  .about__text {
    padding: 30px 30px 50px 30px;
  }
  .callto__text .white-btn {
    margin-left: 5px;
  }
  .instagram__post__item {
    width: calc(100% - 10px);
  }
  .callto__text .primary-btn {
    padding: 14px 14px 12px;
  }
  .services__tab__details__form {
    padding: 30px;
  }
  .cart__total {
    padding: 20px;
  }
  .cart__total a {
    letter-spacing: 0;
    padding: 14px 15px 12px;
  }
  .cart__coupon form button {
    letter-spacing: 0px;
    padding: 0 15px;
  }
  .checkout__proceed__coupon .coupon__input button {
    letter-spacing: 0;
    padding: 0 15px;
  }
  .checkout__form .site-btn {
    letter-spacing: 0;
    padding: 14px 15px;
  }
  .product__details__price {
    position: relative;
    margin-bottom: 20px;
  }
  .product__details__title {
    margin-bottom: 20px;
  }
  .product__details__btns .quantity {
    float: none;
    margin-right: 0;
  }
  .blog__hero__text {
    padding: 40px 35px;
  }
  .blog__details__comment__item a {
    position: relative;
    margin-top: 15px;
  }
  .cart__table tbody tr td.cart__item .cart__item__text h4 {
    font-size: 16px;
  }
}

.about.spad > container:first-child {
  padding-bottom: 20px
}

