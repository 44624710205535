.cart__item__pic {
  max-width: 100px;
  max-height: 100px;
}

.clearButton:hover{
  cursor: pointer;
}

.missing-field-error {
  color: orangered!important;
}

input.required {
  border: 1px solid orangered!important;
}